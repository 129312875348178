import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { OCRA } from './App'
import { getRegistrationTermsOfUse } from '../actions/login'

export const mapStateToProps = state => {
	return {
		messages: state.ocra.messages,
		locale: state.locale.locale,
		termsOfUse: state.ocra.termsOfUse,
		outageMessage: state.messages.outageMessage,
		isMaintenanceWindowOn:
			state.loginWidget.componentDetails.outageManagement
				.maintenanceMessageFlag === 'Y'
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onTermsOfUse() {
			dispatch(getRegistrationTermsOfUse(true))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(OCRA)
)
