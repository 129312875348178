import React from 'react'
import { AppNavigator, CEOSessionManager } from '@wf-wfria/ria'
import { FormattedMessage } from 'react-intl'
import CreateAccount from '../containers/CreateAccount'
import Login from '../containers/Login'
import { Footer } from '../components/Footer'
import { HeaderMenu } from '../components/HeaderMenu'
import { SystemMessages } from '../components/SystemMessages'
import { SystemMessage } from '../../components/SystemMessages'
import { getOcraModeConfig } from '../../helpers/ocraModeConfig'
import '../../styling/index.css'
import { NoSignUpMessage } from '../components/NoSignUpMessage'
import { formatHTMLLang } from '../../helpers/languageHelper'

export function OCRA({
	intl,
	region,
	mode,
	messages,
	locale,
	onLanguageChange,
	onTermsOfUse,
	termsOfUse,
	outageMessage,
	isMaintenanceWindowOn
}) {
	const { formatMessage } = intl
	const { languageOptions } = getOcraModeConfig(mode, locale)

	return (
		<div
			className="OCRA wf-row wf-col--desk-12 wf-col--tab-12 wf-col--mob-only-12"
			data-wf-efmya-mode={mode}
			data-wf-efmya-region={region}
			lang={formatHTMLLang(locale)}
		>
			<CEOSessionManager
				pingUrl="./"
				type="logon"
				sessionBannerMessage={formatMessage({
					id: 'app-session-timeout-warning.message'
				})}
			/>
			<AppNavigator
				headerUtility={
					<HeaderMenu
						locale={locale}
						show={region === 'NA' || region === 'EMEA' || region === 'ANZ'}
						languageOptions={languageOptions}
						onLanguageChange={onLanguageChange}
						formatMessage={formatMessage}
					/>
				}
			>
				<div className="OCRAHeaderContainer">
					<div className="OCRA_Header" />
					<div className="HeaderOverlay">
						<FormattedMessage id="ocra.header.welcome.text" />
						<h1>
							<FormattedMessage id="ocra.header.credit.text" />
						</h1>
						<p>
							<FormattedMessage id="ocra.header.managing1.text" />
							<b>
								<FormattedMessage id="ocra.header.managing2.text" />
							</b>{' '}
							<FormattedMessage id="ocra.header.managing3.text" />{' '}
						</p>
					</div>
				</div>
				<SystemMessages messages={messages} />
				<SystemMessage
					className="GeneralMessage"
					show={outageMessage.show}
					iconKind={outageMessage.iconKind}
					message={outageMessage.message}
				/>
				{!isMaintenanceWindowOn && (
					<div className="Containers wf-row wf-col--desk-11 wf-col--tab-12">
						<div className="form-required-field-legend wf-col--12">
							<span className="wf-u-form-required-indicator">
								{formatMessage({
									id: 'ocra.create-user-id.required.field.label'
								})}
							</span>
						</div>
						<App region={region} mode={mode} />
					</div>
				)}
				<Footer
					formatMessage={formatMessage}
					locale={locale}
					onTermsOfUse={onTermsOfUse}
					termsOfUse={termsOfUse}
				/>
			</AppNavigator>
		</div>
	)
}

export function App({ region, mode }) {
	switch (region) {
		case 'ANZ':
			return (
				<React.Fragment>
					<CreateAccount mode={'ANZ'} />
					<Login />
				</React.Fragment>
			)
		case 'EMEA':
			return (
				<React.Fragment>
					<CreateAccount mode={'EMEA'} />
					<Login />
				</React.Fragment>
			)
		default:
			return (
				<React.Fragment>
					{['ocraNAREVUS', 'ocraNAREVCAN', 'ocraNAHUSUS'].includes(mode) ? (
						<NoSignUpMessage />
					) : (
						<CreateAccount />
					)}
					<Login />
				</React.Fragment>
			)
	}
}
