import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Panel } from '@wf-wfria/ria'
import { BasicText } from '@wf-escon/react-data-driven-components'

export function Footer({ locale, formatMessage, onTermsOfUse, termsOfUse }) {
	const additionalCountryId = getAdditionalCountryLink(locale)
	return (
		<Fragment>
			<div className="Footer">
				<p>
					<FormattedMessage id="ocra.footer.copy.right" />
				</p>
				<p>
					<Button
						className="footer-disclaimer-link"
						kind="simple"
						target="_blank"
						rel="noreferrer"
						href={additionalCountryId}
					>
						<FormattedMessage id="ocra.footer.disclaimer-link-text" />
					</Button>
				</p>
				<p>
					<FormattedMessage id="ocra.footer.content" />
				</p>
			</div>
			<br />
			<FooterLinks
				locale={locale}
				formatMessage={formatMessage}
				onTermsOfUse={onTermsOfUse}
				termsOfUse={termsOfUse}
			/>
		</Fragment>
	)
}

export function FooterLinks({
	locale,
	formatMessage,
	onTermsOfUse,
	termsOfUse
}) {
	const [openPanel, setOpenPanel] = useState(false)
	const links = getFooterLinks(locale)
	function onClosePanel() {
		setOpenPanel(false)
	}

	function onOpenPanel() {
		onTermsOfUse()
		setOpenPanel(true)
	}

	return (
		<div className="footer-link-container">
			{links.map(({ label, href, href2, label2 }, index) => {
				if (href2) {
					return (
						<Fragment key={index}>
							<Button
								{...getHrefAttributes(href, onOpenPanel)}
								kind="simple"
								label={formatMessage({ id: label })}
							></Button>
							<FormattedMessage id="ocra.footer.links.title.and" />
							<Button
								{...getHrefAttributes(href2, onOpenPanel)}
								kind="simple"
								label={formatMessage({ id: label2 })}
							></Button>
							{index !== links.length - 1 && <span>|</span>}
						</Fragment>
					)
				}
				return (
					<Fragment key={index}>
						<Button
							{...getHrefAttributes(href, onOpenPanel)}
							kind="simple"
							label={formatMessage({ id: label })}
						></Button>
						{index !== links.length - 1 && <span>|</span>}
					</Fragment>
				)
			})}
			<Panel
				className="OCRA_TermsOfUse"
				onClose={onClosePanel}
				open={openPanel}
				customStrings={{
					closeButtonAriaLabel: formatMessage({
						id: 'ocra.application.dialog.close'
					})
				}}
				title={formatMessage({ id: 'ocra.terms-of-use.header.title' })}
				elementToFocusOnClose={'footer-link TermsOfUse'}
			>
				<BasicText text={termsOfUse} wrapped={true} />
			</Panel>
		</div>
	)
}

export function getHrefAttributes(href, onOpenPanel) {
	if (!href) {
		return {
			onClick: onOpenPanel,
			className: 'footer-link TermsOfUse'
		}
	}
	return {
		href: href,
		target: '_blank',
		rel: 'noreferrer',
		className: 'footer-link'
	}
}
export function getAdditionalCountryLink(local) {
	switch (local) {
		case 'en':
			return 'https://www.wellsfargomedia.com/wholesale/CDF/COMS%20Country%20and%20Regional%20Disclaimers_2019_ENG_FINALv2.pdf'
		case 'fr':
			return 'https://www.wellsfargomedia.com/wholesale/CDF/COMS%20Country%20and%20Regional%20Disclaimers_2019_FRCA_FINAL.pdf'
		case 'en_GB':
			return 'https://www.wellsfargomedia.com/wholesale/CDF/COMS%20Country%20and%20Regional%20Disclaimers_2019_ENG_FINALv2.pdf'
		case 'fr_FR':
			return 'https://www.wellsfargomedia.com/wholesale/CDF/COMS%20Country%20and%20Regional%20Disclaimers_2019_FRemea_final.pdf'
		case 'de_DE':
			return 'https://www.wellsfargomedia.com/wholesale/CDF/COMS%20Country%20and%20Regional%20Disclaimers_2019_DE_final.pdf'
		case 'au_AU':
			return 'https://www.wellsfargomedia.com/wholesale/CDF/COMS%20Country%20and%20Regional%20Disclaimers_2019_ENG_FINALv2.pdf'
		default:
			return ' '
	}
}
export function getFooterLinks(local) {
	const pathname = window.location.pathname
	switch (local) {
		case 'en':
			return [
				{
					href:
						'https://global.wf.com/distribution-and-inventory-finance-us-privacy-notice/',
					label: 'ocra.footer.links.title.privacy-notice-us'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy',
					label: 'ocra.footer.links.title.privacy-notice-international'
				},
				{
					label: 'ocra.footer.links.title.terms-of-use'
				},
				{
					href: `${pathname}?OCRA_Security&locale=en_US&mode=ocraNA`,
					label: 'ocra.footer.links.title.security'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy/online',
					label: 'ocra.footer.links.title.digital-privacy-cookie-policy'
				},
				{
					href:
						'http://www.wellsfargo.com/privacy-security/privacy/california-consumer-privacy-notice',
					label: 'ocra.footer.links.title.california-consumer-privacy',
					href2:
						'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
					label2: 'ocra.footer.links.title.notice-of-data-collection'
				}
			]
		case 'au_AU':
			return [
				{
					href:
						'https://global.wf.com/distribution-and-inventory-finance-us-privacy-notice/',
					label: 'ocra.footer.links.title.privacy-notice-us'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy',
					label: 'ocra.footer.links.title.privacy-notice-international'
				},
				{
					label: 'ocra.footer.links.title.terms-of-use'
				},
				{
					href: `${pathname}?OCRA_Security&locale=au_AU&mode=ocraANZ`,
					label: 'ocra.footer.links.title.security'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy/online',
					label: 'ocra.footer.links.title.digital-privacy-cookie-policy'
				},
				{
					href:
						'http://www.wellsfargo.com/privacy-security/privacy/california-consumer-privacy-notice',
					label: 'ocra.footer.links.title.california-consumer-privacy',
					href2:
						'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
					label2: 'ocra.footer.links.title.notice-of-data-collection'
				}
			]
		case 'fr':
			return [
				{
					href:
						'https://global.wf.com/distribution-and-inventory-finance-us-privacy-notice/',
					label: 'ocra.footer.links.title.privacy-notice-us'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy',
					label: 'ocra.footer.links.title.privacy-notice-international'
				},
				{
					label: 'ocra.footer.links.title.terms-of-use'
				},
				{
					href: `${pathname}?OCRA_Security&locale=fr_CA&mode=ocraNA`,
					label: 'ocra.footer.links.title.security'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy/online',
					label: 'ocra.footer.links.title.digital-privacy-cookie-policy'
				},
				{
					href:
						'http://www.wellsfargo.com/privacy-security/privacy/california-consumer-privacy-notice',
					label: 'ocra.footer.links.title.california-consumer-privacy',
					href2:
						'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
					label2: 'ocra.footer.links.title.notice-of-data-collection'
				}
			]
		case 'en_GB':
			return [
				{
					href:
						'https://global.wf.com/distribution-and-inventory-finance-us-privacy-notice/',
					label: 'ocra.footer.links.title.privacy-notice-us'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy',
					label: 'ocra.footer.links.title.privacy-notice-international'
				},
				{
					label: 'ocra.footer.links.title.terms-of-use'
				},
				{
					href: `${pathname}?OCRA_Security&locale=en_GB&mode=ocraEMEA`,
					label: 'ocra.footer.links.title.security'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy/online',
					label: 'ocra.footer.links.title.digital-privacy-cookie-policy'
				},
				{
					href:
						'http://www.wellsfargo.com/privacy-security/privacy/california-consumer-privacy-notice',
					label: 'ocra.footer.links.title.california-consumer-privacy',
					href2:
						'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
					label2: 'ocra.footer.links.title.notice-of-data-collection'
				}
			]
		case 'fr_FR':
			return [
				{
					href:
						'https://global.wf.com/distribution-and-inventory-finance-us-privacy-notice/',
					label: 'ocra.footer.links.title.privacy-notice-us'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy',
					label: 'ocra.footer.links.title.privacy-notice-international'
				},
				{
					label: 'ocra.footer.links.title.terms-of-use'
				},
				{
					href: `${pathname}?OCRA_Security&locale=fr_FR&mode=ocraEMEA`,
					label: 'ocra.footer.links.title.security'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy/online',
					label: 'ocra.footer.links.title.digital-privacy-cookie-policy'
				},
				{
					href:
						'http://www.wellsfargo.com/privacy-security/privacy/california-consumer-privacy-notice',
					label: 'ocra.footer.links.title.california-consumer-privacy',
					href2:
						'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
					label2: 'ocra.footer.links.title.notice-of-data-collection'
				}
			]
		case 'de_DE':
			return [
				{
					href:
						'https://global.wf.com/distribution-and-inventory-finance-us-privacy-notice/',
					label: 'ocra.footer.links.title.privacy-notice-us'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy',
					label: 'ocra.footer.links.title.privacy-notice-international'
				},
				{
					label: 'ocra.footer.links.title.terms-of-use'
				},
				{
					href: `${pathname}?OCRA_Security&locale=de_DE&mode=ocraEMEA`,
					label: 'ocra.footer.links.title.security'
				},
				{
					href: 'https://www.wellsfargo.com/privacy-security/privacy/online',
					label: 'ocra.footer.links.title.digital-privacy-cookie-policy'
				},
				{
					href:
						'http://www.wellsfargo.com/privacy-security/privacy/california-consumer-privacy-notice',
					label: 'ocra.footer.links.title.california-consumer-privacy',
					href2:
						'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
					label2: 'ocra.footer.links.title.notice-of-data-collection'
				}
			]
		default:
			return []
	}
}
