import React, { useEffect } from 'react'
import { CEOSessionManager, Footer as WFRIAFooter, Button } from '@wf-wfria/ria'
import { FooterLinks } from '../OCRA/components/Footer'
import { SystemMessage } from '../components/SystemMessages'
import { Header } from '../components/Header'
import { CustomWaitMessage } from '../components/CustomWaitMessage'
import { EqualHousing } from '../components/EqualHousing'
import TermsAndConditions from '../containers/TermsAndConditions'
import ESignConsent from '../containers/ESignConsent'
import {
	ONBOARD,
	WFINFOLEASE,
	LGMTBOB,
	PLINFOLEASE,
	RICOH,
	DLO,
	COBO,
	WFORMS,
	SBA,
	RFI,
	EASYCONNECT
} from '../constants/businessSegmentBrandIds'
import '@wf-wfria/ria/lib/css/Typography/Typography.css'
import '../styling/App.css'
import { formatHTMLLang } from '../helpers/languageHelper'

export function App({
	mode,
	auth,
	productName,
	generalMessage,
	outageMessage,
	widget,
	showLoading,
	hideWidgetContent,
	componentDetailsOutage,
	intl,
	locale,
	termsOfUse,
	showLanguageDropDown,
	onGetAttorneyTermsOfUse,
	onGetBorrowerTermsOfUse,
	onGetAttorneyPrivacyPolicy,
	onGetTermsOfUse,
	onGetPrivacyPolicy,
	onShowFeedback,
	onChangeLanguageDropDown,
	onFocusInput,
	onHideAllMessages,
	onTermsOfUse
}) {
	const links = selectLinks(
		intl.formatMessage,
		mode,
		widget.props.name,
		onGetAttorneyPrivacyPolicy,
		onGetBorrowerTermsOfUse,
		onGetTermsOfUse,
		onGetAttorneyTermsOfUse,
		onGetPrivacyPolicy,
		onFocusInput,
		onHideAllMessages,
		locale
	)

	return (
		<div
			className="App"
			data-brand-id={mode}
			lang={formatHTMLLang(locale)}
			data-mode-id={mode}
		>
			<CEOSessionManager
				pingUrl="./"
				type="logon"
				sessionBannerMessage={intl.formatMessage({
					id: 'app-session-timeout-warning.message'
				})}
			/>
			<Header
				productName={productName}
				mode={mode}
				auth={auth}
				hideNavItems={componentDetailsOutage}
				onShowFeedback={onShowFeedback}
				locale={locale}
				showLanguageDropDown={showLanguageDropDown}
				intl={intl}
				onChangeLanguageDropDown={onChangeLanguageDropDown}
			/>
			<div role="alert">
				<SystemMessage
					className="GeneralMessage"
					show={generalMessage.show}
					iconKind={generalMessage.iconKind}
					messageId={getMessageIdDetail(generalMessage.messageId)}
					values={generalMessage.values}
				/>
				<SystemMessage
					className="GeneralMessage"
					show={outageMessage.show}
					iconKind={outageMessage.iconKind}
					message={outageMessage.message}
				/>
			</div>
			<WidgetContent widget={widget} hideWidgetContent={hideWidgetContent} />
			<ESignConsent />
			<TermsAndConditions />
			<CustomWaitMessage isLoading={showLoading} />
			<Footer
				hideFooter={componentDetailsOutage}
				mode={mode}
				intl={intl}
				links={links}
				locale={locale}
				widgetName={widget.props.name}
				termsOfUse={termsOfUse}
				onTermsOfUse={onTermsOfUse}
			/>
			<EqualHousing hideEqualHousing={componentDetailsOutage} mode={mode} />
		</div>
	)
}

export function WidgetContent({ widget, hideWidgetContent }) {
	if (hideWidgetContent) {
		return null
	}
	return widget
}

export function Footer({
	hideFooter,
	mode,
	links,
	intl,
	widgetName,
	locale,
	onTermsOfUse,
	termsOfUse
}) {
	const { formatMessage } = intl
	const isSecurity = RegExp(/\?.*\bOCRA_Security\b/)
	const YEAR_CREATED = '2020'
	useEffect(() => {
		const element = document.querySelector('footer > small')
		if (mode === SBA || mode === EASYCONNECT || mode === WFORMS) {
			if (element && typeof element.textContent === 'string') {
				element.textContent = element.textContent.replace(/\d{4}/, YEAR_CREATED)
			}
		}
	}, [YEAR_CREATED, mode])

	if (hideFooter || window.location.search.match(isSecurity) || mode === RFI) {
		return null
	}

	const isOCRA = RegExp(/ocra/)
	if (mode.match(isOCRA) && widgetName === 'faqs') {
		return (
			<div id="ocraFaqs">
				<FooterLinks
					formatMessage={formatMessage}
					locale={locale}
					onTermsOfUse={onTermsOfUse}
					termsOfUse={termsOfUse}
				/>
			</div>
		)
	}
	if (mode === PLINFOLEASE || mode === RICOH) {
		const hasLinks = links && links.length > 0

		const disclaimerAndLinks = (
			<div>
				{formatMessage({
					id: 'footer.non-commercial-connect.privacy.disclaimer'
				})}
				{hasLinks && (
					<nav
						aria-label={formatMessage({
							id: 'footer.non-commercial-connect.default-nav-aria-label'
						})}
					>
						<ul className="wf-footer__link-list">
							{links.map(({ label, href, ...rest }) => (
								<li key={label} className="wf-footer__link-item">
									<Button
										kind="simple"
										href={href}
										className={'wf-footer__link'}
										{...rest}
									>
										{label}
									</Button>
								</li>
							))}
						</ul>
					</nav>
				)}
			</div>
		)
		return (
			<WFRIAFooter
				noDefaultLinks
				customContent={disclaimerAndLinks}
				singleCopyrightYear
				customStrings={{
					allRightsReserved: formatMessage({
						id: `footer.all-rights-resererd.label-${mode}`
					})
				}}
				links={links}
			/>
		)
	}
	if (mode === WFINFOLEASE) {
		return (
			<WFRIAFooter
				noDefaultLinks
				singleCopyrightYear
				customStrings={{
					allRightsReserved: formatMessage({
						id: `footer.all-rights-resererd.label-${mode}`
					})
				}}
				links={links}
			/>
		)
	}
	return <WFRIAFooter noDefaultLinks links={links} />
}

export function selectLinks(
	formatMessage,
	mode,
	widgetName,
	onGetAttorneyPrivacyPolicy,
	onGetBorrowerTermsOfUse,
	onGetTermsOfUse,
	onGetAttorneyTermsOfUse,
	onGetPrivacyPolicy,
	onFocusInput,
	onHideAllMessages,
	locale
) {
	const wformsMode = /\bmode=wforms\b/gi.test(document.URL)
	const privacySecurityId = 'footer.borrower.privacy-security-legal.label'
	const termsOfUseId = 'footer.borrower.terms-of-use.label'
	const privacySecurityLegalUrl =
		locale === 'fr'
			? 'https://www08.wellsfargomedia.com/assets/pdf/personal/privacy-security/Canada-Customer-Privacy-Notice-French.pdf'
			: 'https://www.wellsfargo.com/privacy-security'
	const generalLinks = [
		{
			href: 'https://www.wellsfargo.com/privacy-security',
			label: formatMessage({
				id: privacySecurityId
			})
		},
		{
			href:
				'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
			label: formatMessage({
				id: 'footer.data-collection.label'
			})
		},
		{
			label: formatMessage({ id: termsOfUseId }),
			onClick: e => {
				e.preventDefault()
				onHideAllMessages()
				onGetBorrowerTermsOfUse()
			},
			onContextMenu: e => {
				e.preventDefault()
			},
			onFocus: e => {
				onFocusInput()
			}
		},
		{
			href: 'http://www.wellsfargo.com',
			label: formatMessage({ id: 'footer.wellsfargo-com.label' })
		}
	]
	const attorneyLinks = [
		{
			href: 'attorney-policy',
			label: formatMessage({
				id: 'footer.attorney.privacy-security-legal.label'
			}),
			onClick: e => {
				e.preventDefault()
				onGetAttorneyPrivacyPolicy()
			},
			onFocus: e => {
				onFocusInput()
			}
		},
		{
			label: formatMessage({ id: 'footer.attorney.terms-of-use.label' }),
			onClick: e => {
				e.preventDefault()
				onGetAttorneyTermsOfUse()
			},
			onContextMenu: e => {
				e.preventDefault()
			},
			onFocus: e => {
				onFocusInput()
			}
		}
	]
	const nonCommercialConnectLinks = [
		{
			label: formatMessage({
				id: 'footer.non-commercial-connect.terms-of-use.label'
			}),
			onClick: e => {
				e.preventDefault()
				onGetTermsOfUse()
			},
			onContextMenu: e => {
				e.preventDefault()
			}
		}
	]
	const dataCollection = {
		href:
			'https://www.wellsfargo.com/privacy-security/notice-of-data-collection',
		label: formatMessage({
			id: 'footer.data-collection.label'
		})
	}
	const ceoLink = {
		href: 'https://wellsoffice.ceo.wellsfargo.com',
		label: formatMessage({
			id: 'footer.ceo-link.label'
		})
	}
	const infoleasePrivacyLink = {
		href: privacySecurityLegalUrl,
		label: formatMessage({
			id: 'footer.non-commercial-connect.privacy-security-legal.label'
		})
	}
	const nonInfoleaseNonCCPrivacyLink = {
		href: 'privacy-security-legal',
		label: formatMessage({
			id: 'footer.non-commercial-connect.privacy-security-legal.label'
		}),
		onClick: e => {
			e.preventDefault()
			onGetPrivacyPolicy()
		}
	}
	const wellsFargoLink = {
		href: 'http://www.wellsfargo.com',
		label: formatMessage({ id: 'footer.wellsfargo-com.label' })
	}
	if (mode === WFORMS || mode === LGMTBOB) {
		if (wformsMode) {
			return [...attorneyLinks, wellsFargoLink]
		}
		if (widgetName === 'wca' || widgetName === 'faqs') {
			return generalLinks
		}
		return generalLinks
	}

	if (mode === WFINFOLEASE) {
		const canadaMode = /\bmode=canada\b/gi.test(document.URL)
		if (!canadaMode) {
			return [
				wellsFargoLink,
				ceoLink,
				infoleasePrivacyLink,
				dataCollection,
				...nonCommercialConnectLinks
			]
		} else {
			return [
				wellsFargoLink,
				ceoLink,
				infoleasePrivacyLink,
				...nonCommercialConnectLinks
			]
		}
	}
	if (
		mode === SBA ||
		mode === ONBOARD ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return [
			infoleasePrivacyLink,
			dataCollection,
			...nonCommercialConnectLinks,
			wellsFargoLink
		]
	}
	if (mode === PLINFOLEASE || mode === RICOH) {
		return [
			nonInfoleaseNonCCPrivacyLink,
			...nonCommercialConnectLinks,
			dataCollection
		]
	}
	return [nonInfoleaseNonCCPrivacyLink, ...nonCommercialConnectLinks]
}

export function getMessageIdDetail(messageId) {
	if (!messageId) {
		return 'api-service-error.CLDP0000.message'
	}
	if (messageId.includes('CLDP1029')) {
		return 'api-service-error.CLDP1029.message'
	}
	return messageId
}
